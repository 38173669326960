import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-container',{staticClass:"mb-6 mt-6 p-0",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"mt-0"},[_c(VDataTable,{staticClass:"systems-parameter",attrs:{"headers":_vm.headers,"items":_vm.data,"item-key":"id","items-per-page":-1,"group-by":"group","group-desc":true,"hide-default-footer":""},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
var items = ref.items;
return [_c(VIcon,{on:{"click":function($event){$event.stopPropagation();return _vm.expandAll.apply(null, arguments)}}},[_vm._v("mdi-"+_vm._s(_vm.isExpandedNew ? 'unfold-more-horizontal' : 'unfold-less-horizontal')+" ")]),_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"group.header",fn:function(ref){
                var toggle = ref.toggle;
                var group = ref.group;
                var isOpen = ref.isOpen;
                var headers = ref.headers;
                var items = ref.items;
return [_c('td',{staticClass:"pl-0 no-background"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c(VBtn,{ref:group,attrs:{"data-open":isOpen,"icon":""},on:{"click":toggle}},[_c(VIcon,[_vm._v("mdi-"+_vm._s(isOpen ? 'chevron-down' : 'chevron-up'))])],1),_c('span',{staticClass:"font-weight-boldest"},[_vm._v(" "+_vm._s(_vm.convertGroupTitle(group))+" ")])],1)])]),_c('td'),_c('td',[_c('span',{staticClass:"summary"},[_vm._v(" "+_vm._s(items[0].totalCalories)+" "),_c('span',{staticClass:"unit",staticStyle:{"font-weight":"normal"}},[_vm._v("kcal")])])]),_c('td'),_c('td')]}},{key:"item",fn:function(ref){
                var item = ref.item;
                var expand = ref.expand;
                var isExpanded = ref.isExpanded;
return [_c('tr',[_c('td',{staticClass:"pt-2 pb-2"},[_c('img',{staticClass:"rounded-sm thumb mr-2",attrs:{"src":item.image,"alt":""}}),_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"exercise-icon mr-2"}),_vm._v(" "+_vm._s(item.portion))]),_c('span',{staticClass:"unit pl-2"},[_vm._v(" "+_vm._s(item.unit))])])]),_c('td',[_vm._v(" "+_vm._s(item.calories)+" "),_c('span',{staticClass:"unit"},[_vm._v("kcal")])]),_c('td',[_vm._v(_vm._s(item.note))]),_c('td',[_c('div',{staticClass:"d-flex"},_vm._l((item.images),function(image,index){return _c('div',{key:index,staticClass:"preview"},[_c('img',{staticClass:"preview-image",attrs:{"src":image.url,"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.handlePreview(image.url)}}})])}),0)])])]}}])})],1),_c('image-preview',{attrs:{"image":_vm.imagePreview},on:{"update:image":function($event){_vm.imagePreview=$event}}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }